@font-face {
  font-family: GothamLight;
  src: url('/css/fonts/GothamLight.otf') format('opentype'),
    url('/css/fonts/GothamLight.ttf') format('truetype');
}
@font-face {
  font-family: GothamBold;
  font-weight: bold;
  src: url('/css/fonts/GothamRegular.otf') format('opentype'),
    url('/css/fonts/GothamRegular.ttf') format('truetype');
}

* {
  font-family: Roboto;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: #3d3d3d;
  font-family: 'GothamLight', sans-serif;
}
h1,
h2 {
  font-weight: bold;
}
.font-gotham-light {
  font-family: GothamLight, sans-serif;
}
.font-gotham-bold {
  font-family: GothamBold, sans-serif;
}

:root {
  --nobo-main-color: #ff8a80;
  --nobo-main-color-darker: #ff7366;
  --nobo-main-color-lighter: #ffa299;
  --nobo-main-color-light: #ffe8e6;
  --nobo-grey-color: #f1f3f5;
  --nobo-grey-color-alt: #cfd8dc;
  --nobo-black-color: #434347;
  --nobo-black-color-alt: #37474f;
  --nobo-main-color-transparent: rgba(255, 138, 128, 0.3);
  --nobo-navbar-height: 50px;
  --nobo-grey-color-transparent-soft: rgba(241, 243, 245, 0.7);
}

/*Custom bootstrap4 nobo button & input*/
.btn-nobo {
  color: #fff;
  background-color: var(--nobo-main-color);
  border-color: var(--nobo-main-color);
}
.btn-nobo:hover {
  color: #fff;
  background-color: var(--nobo-main-color-darker);
  border-color: var(--nobo-main-color-darker);
}
.btn-nobo:focus,
.btn-nobo.focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-main-color-lighter);
}
.btn-nobo.disabled,
.btn-nobo:disabled {
  color: #fff;
  background-color: var(--nobo-main-color-light);
  border-color: var(--nobo-main-color-light);
}
.btn-nobo:not(:disabled):not(.disabled):active,
.btn-nobo:not(:disabled):not(.disabled).active,
.show > .btn-nobo.dropdown-toggle {
  color: #fff;
  background-color: var(--nobo-main-color);
  border-color: var(--nobo-main-color-darker);
}
.btn-nobo:not(:disabled):not(.disabled):active:focus,
.btn-nobo:not(:disabled):not(.disabled).active:focus,
.show > .btn-nobo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-main-color-lighter);
}

.btn-outline-nobo {
  color: var(--nobo-main-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--nobo-main-color);
}
.btn-outline-nobo:hover {
  color: #fff;
  background-color: var(--nobo-main-color);
  border-color: var(--nobo-main-color);
}
.btn-outline-nobo:focus,
.btn-outline-nobo.focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-main-color-lighter);
}
.btn-outline-nobo.disabled,
.btn-outline-nobo:disabled {
  color: var(--nobo-main-color-lighter);
  background-color: transparent;
}
.btn-outline-nobo:not(:disabled):not(.disabled):active,
.btn-outline-nobo:not(:disabled):not(.disabled).active,
.show > .btn-outline-nobo.dropdown-toggle {
  color: #fff;
  background-color: var(--nobo-main-color-lighter);
  border-color: var(--nobo-main-color);
}
.btn-outline-nobo:not(:disabled):not(.disabled):active:focus,
.btn-outline-nobo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-nobo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-main-color-lighter);
}

.btn-outline-grey {
  color: var(--nobo-black-color-alt);
  background-color: transparent;
  background-image: none;
  border-color: var(--nobo-grey-color);
}
.btn-outline-grey:hover {
  color: var(--nobo-black-color);
  background-color: var(--nobo-grey-color);
  border-color: var(--nobo-grey-color);
}
.btn-outline-grey:focus,
.btn-outline-grey.focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-grey-color-alt);
}
.btn-outline-grey.disabled,
.btn-outline-grey:disabled {
  color: var(--nobo-black-color-alt);
  background-color: transparent;
}
.btn-outline-grey:not(:disabled):not(.disabled):active,
.btn-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn-outline-grey.dropdown-toggle {
  color: var(--nobo-black-color);
  background-color: var(--nobo-black-color-alt);
  border-color: var(--nobo-grey-color);
}
.btn-outline-grey:not(:disabled):not(.disabled):active:focus,
.btn-outline-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-grey-color);
}

/*checkboxes*/
.custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--nobo-main-color);
  border-color: var(--nobo-main-color);
  background-size: 80%;
}
.custom-control.fill-checkbox .fill-control-indicator {
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #aaa;
  transition: 0.3s;
  background: transparent;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

input.form-control.form-control-nobo {
  border-color: var(--nobo-main-color);
}
input.form-control.form-control-nobo:focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-main-color-lighter);
  border-color: var(--nobo-main-color-darker);
}
input.form-control.form-control-grey:focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-grey-color);
  border-color: var(--nobo-grey-color-alt);
}
input.form-control.form-control-black:focus {
  box-shadow: 0 0 0 0.2rem var(--nobo-black-color-alt);
  border-color: var(--nobo-black-color);
}
.nobo-background-main,
.bg-nobo {
  background-color: var(--nobo-main-color);
}
.bg-nobo-transparent {
  background-color: var(--nobo-main-color-transparent);
}
.bg-nobo-lighter {
  background-color: var(--nobo-main-color-lighter);
}
.bg-nobo-darker {
  background-color: var(--nobo-main-color-darker);
}
.bg-nobo-grey {
  background-color: var(--nobo-grey-color);
}
.fix-button-icon-left {
  padding-left: 28px;
}
.fix-button-icon-right {
  padding-right: 28px;
}
.nav-link-nobo.active {
  color: #fff;
  background-color: var(--nobo-main-color) !important;
}
.nav-link-outline-nobo.active {
  color: var(--nobo-main-color) !important;
  background-color: transparent !important;
  border: 2px solid var(--nobo-main-color);
}
.nav-link.nav-link-nobo,
.nav-link.nav-link-outline-nobo {
  color: var(--nobo-main-color);
}
.nav-link.nav-link-nobo:hover,
.nav-link.nav-link-outline-nobo:hover {
  color: var(--nobo-main-color-darker);
}
.nav-link-nobo.active:hover {
  color: #fff;
  background-color: var(--nobo-main-color);
}
.nav-link-outline-nobo.active:hover {
  color: var(--nobo-main-color);
  background-color: transparent !important;
  border: 2px solid var(--nobo-main-color);
}
a.nobo-link {
  color: var(--nobo-main-color);
  text-decoration: none;
}
a.nobo-link:hover {
  color: var(--nobo-main-color-darker);
  text-decoration: none;
}
a.nobo-link.link-white {
  color: white;
}
a.nobo-link.link-white:hover {
  color: whitesmoke;
}
.font-color-nobo {
  color: var(--nobo-main-color);
}
.font-color-black {
  color: var(--nobo-black-color);
}
/*nobo custom end*/

.nobo-text-main {
  color: var(--nobo-main-color);
}

.shadow-soft {
  box-shadow: rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 3px 1px -2px,
    rgba(0, 0, 0, 0.2) 0 1px 5px 0;
}

.debug {
  border: 1px solid red;
}
.material-icons {
  cursor: default;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.d-hidden {
  display: none;
}
.v-hidden {
  visibility: hidden;
}

/*
 * material design
 */

.material-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  top: 2px;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  left: 0;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -8px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type='checkbox']:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type='checkbox']:checked + label::after {
  background: inherit;
  left: 23px;
}

.material-switch > input[type='checkbox'] {
  display: none;
}
.debug {
  border: 1px solid black;
}
.nobo-shadow {
  box-shadow: rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 3px 1px -2px,
    rgba(0, 0, 0, 0.2) 0 1px 5px 0;
}
.nobo-shadow-bottom {
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 30px 0, rgba(0, 0, 0, 0.14) 0 10px 10px 0;
}
.keep-height {
  height: 100%;
}
.keep-width {
  width: 100%;
}
.padding-navbar {
  padding-top: -webkit-calc(var(--nobo-navbar-height) + 40px);
  padding-top: -moz-calc(var(--nobo-navbar-height) + 40px);
  padding-top: calc(var(--nobo-navbar-height) + 40px);
}
.pointer {
  cursor: pointer;
}
.hover-bounce-top:hover {
  animation: bounce-top 0.5s;
}
@keyframes bounce-top {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

.loader {
  border: 3px solid #ffe8e6;
  border-radius: 50%;
  border-top: 3px solid #cb4736;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.vertical-center {
  display: flex;
  align-items: center;
}
