.requestsButton:hover,
.requestsButtonActivated {
  background-color: #133426 !important;
  border: solid #133426 !important;
  color: white !important;
  font-weight: bold !important;
}
.requestsButtonDisabled {
  background-color: white;
  border: solid #133426 !important;
}

.requestTable .-btn {
  background-color: #B7CCBD !important;
}

.handlingRequestButtonContainer {
  position: relative;
  left: 20%;
}

.handleRequestButton {
  background-color: #a9a9a9 !important;
  color: white !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.acceptingButton {
  border: solid rgb(104, 203, 191) !important;
  background-color: rgb(104, 203, 191) !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

.acceptingButton:hover {
  background-color: #20b2aa !important;
  border: solid #20b2aa !important;
  color: white;
}

.decliningButton {
  border: solid #133426 !important;
  background-color: #133426 !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.decliningButton:hover {
  background-color: #E1EDE5 !important;
  border: solid #E1EDE5 !important;
  color: white;
}

.cancelButton {
  background-color: #a9a9a9 !important;
  border: solid #a9a9a9 !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

.cancelButton:hover {
  background-color: #696969 !important;
  border: solid #696969 !important;
  color: white !important;
}
