.-btn {
  background-color: #B7CCBD !important;
  opacity: 1 !important;
}

.-btn:hover {
  color: #000 !important;
}

.rt-th {
  text-align: left;
}

.fullname {
  cursor: pointer;
}

.clientNotified {
  color: #008000;
}

.clientNotNotified {
  color: #ff0000;
}

.-odd,
.-even {
  display: flex !important;
  align-items: center !important;
}

.buttonReportDelay,
.buttonReportAbsence,
.buttonJustificatoryAbsence,
.buttonValidate {
  margin: 0 3px !important;
  font-size: 0.7em !important;
}

.missionCanceled {
  color: #ff8c00;
}

.missionDelayed {
  color: #B7CCBD;
}

.groupedButton {
  display: flex;
  justify-content: center;
}
